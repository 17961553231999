// @flow

import { EntityConnectionContext } from 'src/pages/entities/_contexts/EntityConnectionContext'
import ConditionalRender from 'src/components/_generic/conditional-render'
import React from 'react'
import LDRender from 'src/components/_generic/ld-render'

export default {
	billNumber: {
		label: 'Bill #',
		columnName: 'bill_number',
	},
	assignee: {
		label: 'Assignee',
	},
	entity: {
		label: 'Entity',
		columnName: 'organization_entity',
		wrapper: (content) => (
			<LDRender flagNames={['accountingSoftwareSync']}>
				<EntityConnectionContext.Consumer>
					{(connectionStatus) => (
						<ConditionalRender
							condition={connectionStatus?.has_multiple_entities}
						>
							{content}
						</ConditionalRender>
					)}
				</EntityConnectionContext.Consumer>
			</LDRender>
		),
	},
	vendor: {
		label: 'Vendor',
		columnName: 'vendor',
	},
	locationName: {
		label: 'Ship To',
		columnName: 'location',
	},
	amount: {
		label: 'Amount',
		columnName: 'amount',
		className: 'text-right',
	},
	createdDate: {
		label: 'Created Date',
		columnName: 'created_at',
		className: 'text-center',
	},
	billDate: {
		label: 'Bill Date',
		columnName: 'bill_date',
		className: 'text-center',
	},
	dueDate: {
		label: 'Due Date',
		columnName: 'due_date',
		className: 'text-center',
	},
	billStage: {
		label: 'Bill Stage',
		columnName: 'status',
		className: 'text-center',
	},
	billBuddy: {
		label: 'Bill Buddy',
		columnName: 'bill_buddy_status',
		className: 'text-center',
	},
	coaCoding: {
		label: 'COA Coding',
		columnName: 'coa_coding_complete',
		className: 'text-center',
	},
	lobCoding: {
		label: 'LOB Coding',
		columnName: 'lob_coding_complete',
		className: 'text-center',
	},
	paymentStatus: {
		label: 'Payment Status',
		columnName: 'payment_status',
		className: 'text-center',
	},
}
