// @flow
export default {
	billNumber: {
		label: 'Bill #',
		columnName: 'bill_number',
	},
	createdDate: {
		label: 'Created Date',
		columnName: 'created_at',
		className: 'text-center',
	},
	age: {
		label: 'Age',
		columnName: 'age',
		className: 'text-center',
	},
	vendor: {
		label: 'Vendor',
		columnName: 'vendor',
	},
	locationName: {
		label: 'Ship To',
		columnName: 'location',
	},
	amount: {
		label: 'Amount',
		columnName: 'amount',
		className: 'text-right',
	},
	billDate: {
		label: 'Bill Date',
		columnName: 'bill_date',
		className: 'text-center',
	},
	dueDate: {
		label: 'Due Date',
		columnName: 'due_date',
		className: 'text-center',
	},
	billBuddy: {
		label: 'Bill Buddy',
		className: 'text-center',
	},
	coaCoding: {
		label: 'COA Coding',
		columnName: 'coa_coding_complete',
		className: 'text-center',
	},
	lobCoding: {
		label: 'LOB Coding',
		columnName: 'lob_coding_complete',
		className: 'text-center',
	},
}
