// @flow
import ProductRequestStatus from 'src/configs/Enums/ProductRequestStatus'
import ShippingProvidersType from 'src/configs/Enums/ShippingProvidersType'
import OwnableType from 'src/configs/Enums/OwnableType'
import ProductFilters from 'src/configs/Enums/ProductFilters'
import OrderRuleComponentType from 'src/configs/Enums/OrderRules/OrderRuleComponentType'
import ComparisonType from 'src/configs/Enums/ComparisonType'
import AttachmentTypes from 'src/configs/Enums/AttachmentTypes'
import AssignmentTypes from 'src/configs/Enums/AssignmentTypes'
import OrderFilters from 'src/configs/Enums/OrderFilters'
import OrderStatus from 'src/configs/Enums/OrderStatus'
import OrderRuleFilters from 'src/configs/Enums/OrderRules/OrderRuleFilters'
import TranscriptionType from 'src/configs/Enums/TranscriptionTypes'
import AccountingCodeType from 'src/configs/Enums/AccountingCodeType'
import PermissionTypes from 'src/configs/Enums/PermissionTypes'
import OrderItemStatus from 'src/configs/Enums/OrderItemStatus'
import BudgetImportDataFieldTypes from 'src/components/Budgets/Import/BudgetImportDataFieldTypes'
import BudgetRuleEntityType from 'src/configs/Enums/BudgetRules/BudgetRuleEntityType'
import ProductStockStatus from 'src/configs/Enums/ProductStockStatus'
import CommentTypes from 'src/configs/Enums/CommentTypes'
import VendorProductImportDataFieldTypes from 'src/components/OrganizationVendors/ImportProducts/VendorProductImportDataFieldTypes'
import FormularyPriceImportDataFieldTypes from 'src/components/Formularies/FormularyPrices/ImportPrices/FormularyPriceImportDataFieldTypes'
import OrganizationVendorConnectionStatus from 'src/configs/Enums/OrganizationVendorConnectionStatus'
import Unit2FAChannelTypes from 'src/configs/Enums/Unit2FAChannelTypes'
import BankingAccountDataTypes from 'src/configs/Enums/BankingAccountDataTypes'
import BillsListHeaders from 'src/configs/Enums/BillsListHeaders'
import AccountingMappableMappableTypes from 'src/configs/Enums/AccountingSoftware/AccountingMappableMappableTypes'
import OrganizationVendorLinkStatus from 'src/configs/Enums/OrganizationVendorLinkStatus'
import BankingPaymentStatuses from 'src/configs/Enums/BankingPaymentStatuses'
import BankingPaymentDirectionTypes from 'src/configs/Enums/BankingPaymentDirectionTypes'
import BankingPaymentTypes from 'src/configs/Enums/BankingPaymentTypes'
import BankingTransactionDirectionTypes from 'src/configs/Enums/BankingTransactionDirectionTypes'
import BankPaymentRejectionReasons from 'src/configs/Enums/BankPaymentRejectionReasons'
import BankingACHCounterpartyAccounTypeOptions from 'src/configs/Enums/BankingACHCounterpartyAccounTypeOptions'
import BillMatchStatus from 'src/configs/Enums/BillMatchStatus'
import PaymentPaymentTypes from 'src/configs/Enums/PaymentPaymentTypes'
import BillsPaymentStatuses from 'src/configs/Enums/BillsPaymentStatuses'
import AllBillsListHeaders from 'src/configs/Enums/AllBillsListHeaders'
import BankPaymentReturnReasons from 'src/configs/Enums/BankPaymentReturnReasons'
import UserBankCustomerAssociationTypes from 'src/configs/Enums/UserBankCustomerAssociationTypes'
import BillSyncStatuses from 'src/configs/Enums/BillSyncStatuses'
import ToastTypes from 'src/configs/Enums/ToastTypes'
import BillSyncErrorType from 'src/configs/Enums/BillSyncErrorType'
import BillStatusTypes from 'src/configs/Enums/BillStatusTypes'
import HubspotRecordTypes from 'src/configs/Enums/Hubspot/HubspotRecordTypes'
import HubspotableTypes from 'src/configs/Enums/Hubspot/HubspotableTypes'
import BillBuddyStatuses from 'src/configs/Enums/BillBuddyStatuses'
import BillCOACodingStatuses from 'src/configs/Enums/BillCOACodingStatuses'
import SageIntacctFlow from 'src/configs/Enums/AccountingSoftware/SageIntacctFlow'
import BillLOBCodingStatuses from 'src/configs/Enums/BillLOBCodingStatuses'
import BillInboxHeaders from 'src/configs/Enums/BillInboxHeaders'

export default {
	accountingCodeType: {
		...AccountingCodeType,
	},
	ACCOUNTING_MAPPABLE_MAPPABLE_TYPES: {
		...AccountingMappableMappableTypes,
	},
	ALL_BILLS_LIST_HEADER: {
		...AllBillsListHeaders,
	},
	assignmentTypes: {
		...AssignmentTypes,
	},
	attachmentTypes: {
		...AttachmentTypes,
	},
	bankingAccountDataTypes: {
		...BankingAccountDataTypes,
	},
	bankingACHCounterpartyAccountTypes: {
		...BankingACHCounterpartyAccounTypeOptions,
	},
	bankingPaymentDirectionTypes: {
		...BankingPaymentDirectionTypes,
	},
	bankingPaymentRejectedReasons: {
		...BankPaymentRejectionReasons,
	},
	bankingPaymentReturnReasons: {
		...BankPaymentReturnReasons,
	},
	bankingPaymentStatus: {
		...BankingPaymentStatuses,
	},
	bankingPaymentTypes: {
		...BankingPaymentTypes,
	},
	bankingTransactionDirectionTypes: {
		...BankingTransactionDirectionTypes,
	},
	BILL_INBOX_HEADER: {
		...BillInboxHeaders,
	},
	BILLS_LIST_HEADER: {
		...BillsListHeaders,
	},
	BILL_MATCH_STATUS: {
		...BillMatchStatus,
	},
	BILL_BUDDY_STATUSES: {
		...BillBuddyStatuses,
	},
	BILL_COA_CODING_STATUSES: {
		...BillCOACodingStatuses,
	},
	BILL_LOB_CODING_STATUSES: {
		...BillLOBCodingStatuses,
	},
	BILL_STATUS_TYPES: {
		...BillStatusTypes,
	},
	billsPaymentStatuses: {
		...BillsPaymentStatuses,
	},
	billSyncErrorType: {
		...BillSyncErrorType,
	},
	billSyncStatuses: {
		...BillSyncStatuses,
	},
	budgetImportDataFieldTypes: {
		...BudgetImportDataFieldTypes,
	},
	BUDGET_RULE_ENTITY_TYPE: {
		...BudgetRuleEntityType,
	},
	COMMENT_TYPES: {
		...CommentTypes,
	},
	COMPARISON_TYPE: {
		...ComparisonType,
	},
	formularyPriceImportDataFieldTypes: {
		...FormularyPriceImportDataFieldTypes,
	},
	HUBSPOT_RECORD_TYPES: {
		...HubspotRecordTypes,
	},
	HUBSPOTABLE_TYPES: {
		...HubspotableTypes,
	},
	ORDER_FILTERS: {
		...OrderFilters,
	},
	ORDER_ITEM_STATUS: {
		...OrderItemStatus,
	},
	ORDER_RULE_COMPONENT_TYPE: {
		...OrderRuleComponentType,
	},
	ORDER_RULE_FILTERS: {
		...OrderRuleFilters,
	},
	ORDER_STATUS: {
		...OrderStatus,
	},
	ORG_VENDOR_CONNECTION_STATUS: {
		...OrganizationVendorConnectionStatus,
	},
	ORG_VENDOR_LINK_STATUS: {
		...OrganizationVendorLinkStatus,
	},
	OWNABLE_TYPE: {
		...OwnableType,
	},
	paymentPaymentTypes: {
		...PaymentPaymentTypes,
	},
	PERMISSION_TYPE: {
		...PermissionTypes,
	},
	PRODUCT_FILTERS: {
		...ProductFilters,
	},
	PRODUCT_REQUEST_STATUS: {
		...ProductRequestStatus,
	},
	PRODUCT_STOCK_STATUS: {
		...ProductStockStatus,
	},
	SAGE_INTACCT_FLOW: {
		...SageIntacctFlow,
	},
	SHIPPING_PROVIDERS_TYPE: {
		...ShippingProvidersType,
	},
	toastTypes: {
		...ToastTypes,
	},
	transcriptionType: {
		...TranscriptionType,
	},
	unit2FAChannelTypes: {
		...Unit2FAChannelTypes,
	},
	UPSERT_EVENT: {
		INITIAL: 1,
		IS_CREATING: 2,
		IS_CREATED: 3,
		IS_UPDATING: 4,
	},
	userBankCustomerAssociationTypes: {
		...UserBankCustomerAssociationTypes,
	},
	vendorProductImportDataFieldTypes: {
		...VendorProductImportDataFieldTypes,
	},
}
