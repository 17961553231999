// @flow

import OrganizationVendor from 'src/apollo/_entities/organizationVendor'

const Vendor = (vendorData) => {
	if (!vendorData) return null

	const organization_vendor = OrganizationVendor({
		...vendorData?.organization_vendor,
		vendor: {
			...vendorData,
			...vendorData?.organization_vendor?.vendor,
		},
	})

	return {
		...vendorData,
		value: vendorData?.id,
		label: !!organization_vendor?.code
			? `${organization_vendor.code} ${vendorData.name}`
			: vendorData.name,
		organization_vendor,
	}
}

export default Vendor
