// @flow
import { format, getUnixTime, parseISO } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

class DateFormatter {
	/** @deprecated */
	standard(timestampMs) {
		if (!timestampMs) {
			return '00:00:00'
		}
		let day = new Date(+timestampMs)

		return format(utcToZonedTime(day, 'Europe/London'), 'MMM d, yyyy')
	}

	/** @deprecated */
	condensed(timestampMs) {
		if (!timestampMs) {
			return '00:00:00'
		}
		let day = new Date(+timestampMs)

		return format(utcToZonedTime(day, 'Europe/London'), 'MM/dd/yy')
	}

	/** @deprecated */
	extendedUserLocale(timestampMs) {
		if (!timestampMs) {
			return '00:00:00'
		}
		let day = new Date(+timestampMs)

		return format(day, "MMMM d, yyyy 'at' h:mm aaaaa'm'")
	}

	/** @deprecated */
	input(timestampMS) {
		if (!timestampMS) {
			return null
		}
		let day = new Date(+timestampMS)

		return format(utcToZonedTime(day, 'Europe/London'), 'yyyy-MM-dd')
	}

	/** @deprecated */
	notAdjusted(timestampMs) {
		let day = new Date(+timestampMs)

		return format(day, 'MMM d, yyyy')
	}

	/** @deprecated */
	custom(timestampMS, customFormat) {
		if (!timestampMS) {
			return null
		}
		let day = new Date(+timestampMS)

		return format(utcToZonedTime(day, 'Europe/London'), customFormat)
	}

	/** @deprecated */
	monthFromTimestampInSeconds(timestampMs) {
		if (!timestampMs) {
			return '00:00:00'
		}

		let day = new Date(
			+timestampMs - new Date().getTimezoneOffset() * 60 * 1000
		)
		return format(day, 'MMMM yyyy')
	}

	/** @deprecated */
	toTimestamp(date) {
		return getUnixTime(Date.parse(date)) * 1000
	}

	/** @deprecated */
	toUserTimestamp(date) {
		return Date.parse(date)
	}

	/** @deprecated */
	toDate(timestampMs) {
		return new Date(+timestampMs)
	}

	/** @deprecated */
	endOfDay(timestampMs: number): number {
		let date = new Date(+timestampMs)

		return this.toTimestamp(
			format(utcToZonedTime(date, 'Europe/London'), `yyyy-MM-dd 23:59:59`)
		)
	}

	humanReadableDate(date: Date | null): string | null {
		if (!date) {
			return null
		}

		return format(date, 'MMM d, y')
	}

	humanReadableDateAndTime(date: Date | null): string | null {
		if (!date) {
			return null
		}

		return format(date, "MMM d, y 'at' h:mm aaaaa'm'")
	}

	humanReadableDateWithSeparator(date: Date | null): string | null {
		if (!date || date === 'Invalid Date') {
			return null
		}

		return format(date, 'MMM d, y')
	}

	humanReadableMonthYear(date: Date | null): string | null {
		if (!date) {
			return null
		}

		return format(date, 'MMMM yyyy')
	}

	/** @deprecated */
	secondsToTimeFormatter(timestamp) {
		const minutes = timestamp / 60

		if (minutes > 59) {
			const hours = minutes / 60

			if (hours > 23) {
				const days = hours / 24
				return `${Math.round(days)} Days`
			}

			return `${Math.round(hours)} Hours`
		}

		if (minutes === 0) {
			return `-`
		}
		if (minutes < 1) {
			return `< 1 Minute`
		}

		return `${Math.round(minutes)} Minutes`
	}

	/** @deprecated */
	millisecondsToTimeFormatter(timestamp) {
		const seconds = timestamp / 1000

		if (seconds >= 60) {
			let minutes = seconds / 60

			if (minutes >= 60) {
				let hours = minutes / 60

				if (hours >= 24) {
					const days = Math.floor(hours / 24)

					return `${days} ${days > 1 ? 'days' : 'day'} ago...`
				}

				hours = Math.floor(hours)
				return `${hours} ${hours > 1 ? 'hours' : 'hour'} ago...`
			}

			minutes = Math.floor(minutes)
			return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'} ago...`
		}

		return 'Just now'
	}

	/** @deprecated */
	firstOfTheMonth(timestampMs) {
		if (!timestampMs) {
			return '00:00:00'
		}
		let day = new Date(+timestampMs)

		return format(
			utcToZonedTime(day, 'Europe/London'),
			'yyyy-MM-01 00:00:00'
		)
	}

	/** @deprecated */
	endOfTheMonth(timestampMs) {
		if (!timestampMs) {
			return '00:00:00'
		}
		let date = new Date(+timestampMs)
		let lastDay = new Date(date.getUTCFullYear(), date.getUTCMonth() + 1, 0)

		return format(
			utcToZonedTime(lastDay, 'Europe/London'),
			`yyyy-MM-dd 23:59:59`
		)
	}

	convertUnitFormattedDateStrToCMStandard(dateStr) {
		if (!!dateStr) {
			return format(
				utcToZonedTime(new Date(dateStr), 'Europe/London'),
				'MMM d, yyyy'
			)
		}
	}

	endOfDayDate(date: Date): Date {
		date.setHours(23)
		date.setMinutes(59)
		date.setSeconds(59)
		return date
	}

	isoStringToDate(isoString: string): Date {
		return parseISO(isoString)
	}

	isoStringToHumanReadableString(
		isoString: string,
		toLocal: boolean = true,
		includeTime: boolean = true
	): string {
		let date = parseISO(isoString)
		if (!toLocal) {
			date = new Date(
				date.getUTCFullYear(),
				date.getUTCMonth(),
				date.getUTCDate()
			)
		}

		if (includeTime) {
			return this.humanReadableDateAndTime(date)
		} else {
			return this.humanReadableDate(date)
		}
	}

	datePickerSelectionToISOString(date: Date): string {
		const tzo = -date.getTimezoneOffset(),
			dif = tzo >= 0 ? '+' : '-',
			pad = function (num) {
				return (num < 10 ? '0' : '') + num
			}

		return (
			date.getFullYear() +
			'-' +
			pad(date.getMonth() + 1) +
			'-' +
			pad(date.getDate()) +
			'T' +
			pad(date.getHours()) +
			':' +
			pad(date.getMinutes()) +
			':' +
			pad(date.getSeconds()) +
			dif +
			pad(Math.floor(Math.abs(tzo) / 60)) +
			':' +
			pad(Math.abs(tzo) % 60)
		)
	}

	calculateAgeString(date: Date): string {
		const now = new Date()
		const diff = now.getTime() - date.getTime()
		const hours = diff / (1000 * 60 * 60)
		if (hours < 24) {
			return `${Math.floor(hours)} hours`
		}
		const days = hours / 24
		if (days < 365) {
			return `${Math.floor(days)} days`
		}
		const years = days / 365
		return `${Math.floor(years)} years`
	}
}

export default new DateFormatter()
